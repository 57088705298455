import React from "react";
import {
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  SearchInput,
  SelectInput,
  ReferenceField,
  EditButton,
  Pagination,
} from "react-admin";

import ListAside from "./ListAside";
import IdAndNameField from "../IdAndNameField";

const CausalChainsSummaryPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props} linkType={false}>{children}</ReferenceField>
);

const StatusChoices = [
  { id: 'pending', name: 'Pending' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
];

const causalChainSummaryFilters = [
  <SelectInput label="Status" source="status" defaultValue="pending" choices={StatusChoices} />,
  <TextInput label="Periodical" source="periodical" />,
  <SearchInput source="container_id" placeholder="Container ID" />
];

const CausalChainsSummaryList = ({ permissions, ...props }) => {

  return (
    <List
      {...props}
      filters={causalChainSummaryFilters}
      sort={{
        field: "created_at",
        order: "ASC",
      }}
      aside={< ListAside />}
      pagination={< CausalChainsSummaryPagination />}
    >
      <Datagrid optimized bulkActionButtons={false}>
        <TextField source="id" />

        <ReferenceField label="Organization" source="container_id" reference="Container" link={false}>
          <>
            <SubReference source="organization_id" reference="Organization" link={false}>
              <IdAndNameField />
            </SubReference>
          </>
        </ReferenceField>

        <ReferenceField
          label="Container"
          source="container_id"
          reference="Container"
          link="show"
        >
          <IdAndNameField />
        </ReferenceField>

        <TextField source="status" />
        <TextField source="periodical" />
        <DateField source="created_at" showTime />

        <EditButton label="Review" />
      </Datagrid>
    </List >
  )
};

export default CausalChainsSummaryList;
